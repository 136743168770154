import React, { useState } from 'react';

import Hero from '../components/index/Hero';
import Welcome from '../components/index/Welcome';
import Seo from '../components/utilities/Seo';
import Navbar from '../components/utilities/Navbar';




const Index = (props) => {

  const [navVisible, setNavVisible] = useState(false);
  
  return ( 
      <>  
        <Seo />
        <Navbar navVisible={navVisible} />
        <Hero slug={props.location.pathname} setNavVisible={setNavVisible}  />
        <Welcome />
      </>
  )
} 

export default Index;

// to run this project, run npm install to get all dependencies