import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { fadeIn, slideUp } from './indexVariants';
import * as welcomeStyles from './welcome.module.scss';

const Welcome = () => {
  const [ref, isVisible] = useInView({ threshold: 0.6 });
  const controls = useAnimation();

  useEffect(() => {
    if(isVisible)
      controls.start('animate');
  }, [isVisible, controls])

  return (
    <section ref={ref} className={welcomeStyles.container}>
      <motion.h1
        custom={1}
        variants={fadeIn}
        initial='initial'
        animate={controls}
        >
        Welcome
      </motion.h1>
      <div className={welcomeStyles.content}>
        <motion.div className={welcomeStyles.sideBar}
          custom={400}
          variants={slideUp}
          initial='initial'
          animate={controls}
          transition={{ delay: 1, duration: 3 }}
        />
        <p>
          Welcome A
        </p>
        <p>
          Welcome B
        </p>
      </div>
    </section>
  )
}

export default Welcome;
