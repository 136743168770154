import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { navVariants } from './navVariants';
import wordmark from '../../images/AlecBaldwinWordMark.png'
import * as navbarStyles from './navbar.module.scss';

const Navbar = ({ navVisible }) => {
  console.log(`navVisible -> ${navVisible}`)
  return (
    <AnimatePresence>
      {
        navVisible && 
        <motion.nav id="navbar" className={navbarStyles.nav}
          variants={navVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div>
            <img className={navbarStyles.wordmark} src={wordmark} alt="Alec Baldwin"/>
          </div>
        </motion.nav>
      }
    </AnimatePresence>
  )
}

export default Navbar
